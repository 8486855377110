import React, { useEffect } from 'react'
import Link from 'next/link'
import { Trans } from '@lingui/macro'

function moveEyeBalls(event: MouseEvent) {
  // Query for all elements with class 'eye', as it seems like you might have more than one
  const eyes = document.querySelectorAll('.eye')

  // Iterate over each 'eye' element
  eyes.forEach(eye => {
    // Corrected to getBoundingClientRect() to get the position and dimensions
    const rect = eye.getBoundingClientRect()
    const x = rect.left + rect.width / 2
    const y = rect.top + rect.height / 2

    // Calculate the angle in radians
    const rad = Math.atan2(event.pageX - x, event.pageY - y)

    // Convert radians to degrees and adjust the rotation
    const rot = rad * (180 / Math.PI) * -1 + 180

    // Apply the CSS transformations
    ;(eye as any).style.transform = 'rotate(' + rot + 'deg)'
    ;(eye as any).style.webkitTransform = 'rotate(' + rot + 'deg)' // For older browsers
  })
}

const ErrorPage = () => {
  useEffect(() => {
    document.addEventListener('mousemove', moveEyeBalls)

    return () => {
      document.removeEventListener('mousemove', moveEyeBalls)
    }
  }, [])

  return (
    <div
      className={
        'w-screen h-screen flex flex-col items-center justify-center bg-primary-50 gap-6'
      }>
      <div className={'flex gap-4'}>
        <div className='eye'></div>
        <div className='eye'></div>
      </div>
      <p className='text-primary'>
        <Trans>
          Oh eyeballs! Something went wrong. We&apos;re <i>looking</i> to see
          what happened.
        </Trans>
      </p>
      <Link href={'/dashboard'}>
        <p className={'font-bold underline text-primary'}>
          <Trans>Get back to safety</Trans>
        </p>
      </Link>
    </div>
  )
}

export default ErrorPage
