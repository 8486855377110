import { useOrgContext } from '@/hooks/state/organization'
import { useCallback } from 'react'

export const getLocaleFromLanguage = (lang?: string) => {
  const locales: {
    [index: string]: {
      locale: string
      options?: { [index: string]: any }
    }
  } = {
    es: { locale: 'es-ES', options: { useGrouping: 'always' } },
    en: { locale: 'en-US' }
  }

  return locales[lang ?? 'en'] ?? 'en-US'
}

export type NumberFormater = (
  num: number,
  style?: string,
  withDecimals?: boolean,
  currency?: string,
  opts?: Intl.NumberFormatOptions
) => string

const useFormatNumber = () => {
  const { selectedOrganization } = useOrgContext(state => {
    return {
      selectedOrganization: state.selectedOrganization
    }
  })

  const formatNumber: NumberFormater = useCallback(
    (
      num: number,
      style = 'currency',
      withDecimals = true,
      currency?: string,
      opts?: Intl.NumberFormatOptions
    ) => {
      const locale = getLocaleFromLanguage(selectedOrganization?.locale)

      let options: Intl.NumberFormatOptions = {
        ...locale.options,
        style,
        //@ts-ignore Seems like tsc does not have full support for Intl yet
        trailingZeroDisplay: 'stripIfInteger',
        ...opts
      }

      if (style === 'currency') {
        options['currency'] =
          currency || selectedOrganization?.currency || 'EUR'
      }

      if (!withDecimals) {
        options['maximumFractionDigits'] = 0
      }

      return new Intl.NumberFormat(locale.locale, options).format(num)
    },
    [selectedOrganization?.currency, selectedOrganization?.locale]
  )

  const getFormatedValue = (value: string, type: string) => {
    switch (type) {
      case 'currency':
      case 'monetary':
        return formatNumber(Number(value), 'currency')
      case 'percentage':
        return formatNumber(
          Number(value) / 100,
          'percent',
          undefined,
          undefined,
          { maximumFractionDigits: 2 }
        )
      case 'numeric':
      case 'number':
      case 'count':
        return formatNumber(Number(value), 'decimal')
      default:
        return value
    }
  }

  return {
    formatNumber,
    getFormatedValue
  }
}

export default useFormatNumber
